
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function relatedItemsRT () {
    function repeatItems1(items, itemsIndex) {
        return [items(function () {
                function mergeProps(inline, external) {
                    var res = Object.assign({}, inline, external);
                    if (inline.hasOwnProperty('style')) {
                        res.style = _.defaults(res.style, inline.style);
                    }
                    if (inline.hasOwnProperty('className') && external.hasOwnProperty('className')) {
                        res.className = external.className + ' ' + inline.className;
                    }
                    return res;
                }
                return _createElement('li', {}, _createElement('product-card', { 'class': 'card card--product h-full card--product-contained card--no-lines card--product-compare relative flex' }, _createElement('div', { 'className': 'card__media image-blend relative' + (!!this.image2 ? ' has-hover-image' : '') }, _createElement('a', {
                    'href': this.url,
                    'aria-label': this.removeHTML(this.title),
                    'className': 'media block relative image-blend js-prod-link',
                    'tabIndex': '-1'
                }, _createElement('img', {
                    'src': this.imageOrDefault(this.image),
                    'alt': this.removeHTML(this.title),
                    'loading': 'lazy',
                    'className': 'img-fit card__main-image',
                    'onError': this.onImageError
                }), !!this.image2 ? _createElement('img', {
                    'src': this.imageOrDefault(this.image2),
                    'loading': 'lazy',
                    'className': 'img-fit card__hover-image',
                    'alt': this.removeHTML(this.title),
                    'onError': e => this.onImageError(e, 'image2'),
                    'key': '559'
                }) : null), this.out_of_stock ? _createElement('div', {
                    'className': 'product-label-container absolute top-0 start',
                    'key': '792'
                }, _createElement('div', { 'className': 'product-label-wrapper flex' }, _createElement('span', { 'className': 'product-label product-label--sold-out' }, 'Sold out'))) : null), _createElement('div', { 'className': 'card__info-container flex flex-col flex-auto relative' }, _createElement('div', { 'className': 'card__info w-full' }, _createElement('div', { 'className': 'card__info-inner inline-block w-full' }, _createElement('p', { 'className': 'card__vendor mb-0 text-sm text-theme-light' }, 'SKU: ', _createElement('span', { dangerouslySetInnerHTML: { __html: this.sku } })), _createElement('p', { 'className': 'card__title font-bold mt-1 mb-0' }, _createElement('a', mergeProps({
                    'href': this.url,
                    'className': 'card-link text-current js-prod-link'
                }, { dangerouslySetInnerHTML: { __html: this.title } }))), _createElement('div', { 'className': 'price' }, _createElement('div', { 'className': 'price__default' }, _createElement('strong', { 'className': 'price__current' }, '\n                ', this.formatPrice(this.price), '\n                ', this.price_varies ? [
                    ' - ',
                    this.formatPrice(this.max_price)
                ] : null))), !this.out_of_stock ? _createElement('div', {
                    'className': 'pt-5 text-xs product-inventory__status',
                    'data-inventory-level': 'normal',
                    'key': '1899'
                }, '\n            In stock\n          ') : null)), _createElement('div', { 'className': 'card__quick-add mob:card__quick-add--icon desktop:card__quick-add--icon' }, _createElement('div', { 'className': 'no-js-hidden' }, _createElement('a', {
                    'href': this.url,
                    'className': 'btn btn--primary w-full'
                }, _createElement('span', { 'className': 'quick-add-btn-icon block pointer-events-none' }, _createElement('svg', {
                    'width': '24',
                    'height': '24',
                    'viewBox': '0 0 24 24',
                    'fill': 'currentColor',
                    'aria-hidden': 'true',
                    'focusable': 'false',
                    'role': 'presentation',
                    'className': 'icon'
                }, _createElement('path', { 'd': 'M12.12 20.44H5.6V9.56h12.8v3.73c.06.4.4.69.8.7.44 0 .8-.35.8-.8v-4.5a.792.792 0 0 0-.8-.69H17V6.5C16.9 4 14.7 2 12 2S7 4.09 7 6.67V8H4.71c-.4.04-.71.37-.71.78v12.53a.8.8 0 0 0 .8.69h7.43c.38-.06.67-.39.67-.78 0-.43-.35-.78-.78-.78ZM8.66 6.67c0-1.72 1.49-3.11 3.33-3.11s3.33 1.39 3.33 3.11V8H8.65V6.67Z' }), _createElement('path', { 'd': 'M20 17.25h-2.4v-2.5a.817.817 0 0 0-.8-.7c-.44 0-.8.36-.8.8v2.4h-2.5c-.4.06-.7.4-.7.8 0 .44.36.8.8.8H16v2.5c.06.4.4.7.8.7.44 0 .8-.36.8-.8v-2.4h2.5c.4-.06.69-.4.7-.8 0-.44-.35-.8-.8-.8Z' })))))))));
            }, { count: undefined })];
    }
    return _createElement('div', { 'className': 'cm_related-products' }, _createElement('div', { 'className': 'cm_related-products-title-container' }, _createElement('h2', { 'className': 'cm_related-products-title' }, 'You might also like...')), _createElement.apply(this, [
        'ul',
        { 'className': 'cm_related-products-list cmRepeater_items' },
        _map(this.items, repeatItems1.bind(this))
    ]));
}
        export const componentNames = []