import shopifyGenericDefaults from '../shopify-generic/config.js';

shopifyGenericDefaults.Widgets.find((w) => w.name === 'SearchHeader').sortEntries = {
  'relevance': 'Best Match',
  'title': 'Name, A-Z',
  'title:desc': 'Name, Z-A',
  'collection_sort': 'Featured',
  'price': 'Price, Low to High',
  'max_price:desc': 'Price, High to Low',
  'published_at': 'Date, Old to New',
  'published_at:desc': 'Date, New to Old',
};

export default {
  includes: ['shopify-generic'],
  ...shopifyGenericDefaults,
};
